/* eslint-disable @typescript-eslint/naming-convention */
import {
  createAxiosInstance,
  type AxiosError,
  type InternalAxiosRequestConfig,
} from '@astronautsid/wpe-utils/helpers/axiosIntance'

import { getIronSession } from 'iron-session'

import { axiosErrorLoggerInterceptor } from 'config/axiosLogger'
import { APP_VERSION } from 'config/constants'
import { safeJsonParse } from 'utils/helpers/safeJson'

import { SLICE_NAME } from 'store/user'
import { SessionDataType, sessionOptions } from 'lib/session/config'

const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || ''

const isServer = typeof window === 'undefined'

const handleResponseReject = (error: AxiosError) => {
  if (!isServer) {
    axiosErrorLoggerInterceptor(error)
    /* @FIXME: Temporarily commented until all whitelisted endpoints decided */
    // const whitelistedEndpoints = [
    //   'carts',
    //   '/d/'
    // ]
    // const requestUrl = String(error.config?.url || '')
    // // Check if any whitelisted endpoint is a substring of the request URL
    // const isWhitelisted = whitelistedEndpoints.some((endpoint: string) =>
    //   requestUrl.includes(endpoint),
    // )
    // /* Eject user when response is 401 */
    // if (error.response?.status === 401 && !isWhitelisted) {
    //   window.location.href = '/login'
    // }
  }

  return Promise.reject(error)
}

const getToken = async () => {
  if (isServer) {
    const { cookies } = await import('next/headers')
    const session = await getIronSession<SessionDataType>(cookies(), sessionOptions)
    return session.token
  }

  return safeJsonParse(localStorage.getItem(SLICE_NAME) as string)?.state?.token
}

const requestInterceptor = async (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  // No Re-assign headers.Authorization if exist
  if (config.headers.Authorization) {
    return config
  }

  let token = await getToken()
  token = !config.url?.startsWith(process.env.NEXT_PUBLIC_API_BASE_URL as string) ? token : null

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token ? `Bearer ${String(token)}` : ''

  return config
}

const axiosInstanceApiGateway = createAxiosInstance(
  {
    baseURL: API_BASE_URL,
    headers: {
      'Access-Control-Allow-Origin':
        process.env.NODE_ENV !== 'production' ? 'http://localhost:3000' : undefined,
      'Access-Control-Max-Age': 7200,
      'X-API-VERSION': '1.9.4',
      'X-APP-VERSION': 'android-2.17.1',
      'X-DEVICE': 'web-customer',
      'X-DEVICE-VERSION': APP_VERSION,
    },
    maxRedirects: 0,
    timeout: 60000, // 1 minutes timeout since default timeout is disabled.
  },
  {
    interceptorResponseReject: handleResponseReject,
    interceptorRequest: requestInterceptor as never,
  },
)

export default axiosInstanceApiGateway
export type ConfigAxiosType = Parameters<(typeof axiosInstanceApiGateway)['Get']>['0']['config']
