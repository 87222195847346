import React from 'react'
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import { styled, useTheme } from '@astronautsid/wpe-astro-ui/theme'
import { Close } from '@astronautsid/wpe-icons'

import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { DialogContent, DialogContentPropsType } from 'components/AstroUI/DialogContent'

export type CloseReasonType = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick'

export type DialogPropsType = Omit<MuiDialogProps, 'onClose'> & {
  onClose?: (reason: CloseReasonType) => void
  dividers?: boolean
  wichCloseIcon?: boolean
  backdropClick?: boolean
  titleProps?: React.CSSProperties
  dialogContentProps?: DialogContentPropsType
}

export const DialogBase = styled(MuiDialog)<MuiDialogProps>`
  .MuiDialog-paper {
    border-radius: 8px;
  }
`

const BottomSheet = ({
  title,
  titleProps,
  children,
  onClose,
  backdropClick = false,
  dialogContentProps = {},
  wichCloseIcon = true,
  ...rest
}: DialogPropsType) => {
  const theme = useTheme()

  return (
    <DialogBase
      keepMounted
      onClose={(_, reason) => {
        if (!backdropClick && reason === 'backdropClick') return
        if (onClose) {
          onClose(reason)
        }
      }}
      PaperProps={{
        sx: {
          mx: 0,
          width: '100%',
          position: 'absolute',
          bottom: 0,
          margin: 0,
          borderRadius: '12px 12px 0 0 !important',
          overflow: 'inherit',
        },
        elevation: 0,
      }}
      {...rest}
    >
      {wichCloseIcon && (
        <Box
          position="absolute"
          display="flex"
          width="40px"
          height="40px"
          justifyContent="space-around"
          top="-52px"
          right="16px"
          borderRadius="100%"
          bgcolor={theme.palette.neutral['neutral-1']}
        >
          {onClose ? (
            <IconButton sx={{ padding: '0px' }} onClick={() => onClose('closeButtonClick')}>
              <Close color={theme.palette.iconColor.default} size={28} />
            </IconButton>
          ) : null}
        </Box>
      )}

      <DialogContent sx={{ padding: '16px', pt: '8px' }} {...dialogContentProps}>
        {children}
      </DialogContent>
    </DialogBase>
  )
}

export default BottomSheet
