'use client'

import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { Button } from '@astronautsid/wpe-astro-ui/components/atoms/Button'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { TextInput } from '@astronautsid/wpe-astro-ui/components/atoms/TextInput'
import { DatePicker } from '@astronautsid/wpe-astro-ui/components/atoms/DatePicker'
import { useTheme } from '@astronautsid/wpe-astro-ui/theme'
import { Controller } from 'react-hook-form'
import { useTranslations } from 'next-intl'
import { CloseCircle } from '@astronautsid/wpe-icons'
import { useShallow } from 'zustand/react/shallow'

import BottomSheet from 'components/BottomSheet'
import useCategoryCatalogueStore, {
  type CategoryCatalogueSliceType,
} from 'store/category&catalogue/category&catalogueSlice'
import useUserStore, { type UserSliceType } from 'store/user'

import useBottomSheetConfirmationAge from '../../_hooks/useBottomSheetConfirmationAge'

const selectProductDetailStore = ({
  isShowConfirmationAge,
  setIsShowConfirmationAge,
}: CategoryCatalogueSliceType) => ({
  isShowConfirmationAge,
  setIsShowConfirmationAge,
})

const selectUserStore = ({ user }: UserSliceType) => ({
  user,
})

const BottomSheetConfirmationAge = () => {
  const { isShowConfirmationAge, setIsShowConfirmationAge } = useCategoryCatalogueStore(
    useShallow(selectProductDetailStore),
  )
  const { user } = useUserStore(useShallow(selectUserStore))

  const {
    register,
    errors,
    control,
    handleSubmit,
    onSubmit,
    watch,
    handleResetNik,
    handleToDetail,
  } = useBottomSheetConfirmationAge()

  const { palette } = useTheme()
  const t = useTranslations('accountEdit')
  const tBottomSheet = useTranslations('confirmationAge')
  const tGlobal = useTranslations('global')

  return (
    <BottomSheet
      open={isShowConfirmationAge}
      fullWidth
      dialogContentProps={{
        sx: { padding: '16px', pt: '24px' },
      }}
      onClose={() => setIsShowConfirmationAge(false)}
      backdropClick
    >
      {user?.ktp_exist ? (
        <Box
          display="flex"
          flexDirection="column"
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Typography fontSize="20px" lineHeight="28px" align="center" mb="6px">
            {tBottomSheet('form.title')}
          </Typography>
          <Typography
            fontSize="14px"
            align="center"
            fontWeight="400"
            color="textColor.secondaryDark"
          >
            {tBottomSheet('form.description')}
          </Typography>
          <Box mt="24px" display="flex" flexDirection="column" gap="16px">
            <TextInput
              fullWidth
              label={tBottomSheet('form.firstName')}
              name="firstname"
              error={!!errors?.firstname?.message}
              helperText={errors?.firstname?.message ? t(errors.firstname.message) : ''}
              FormHelperTextProps={{
                color: palette.textColor.error,
              }}
              inputProps={{ ...register('firstname') }}
            />
            <TextInput
              fullWidth
              label={tBottomSheet('form.lastName')}
              name="lastname"
              error={!!errors?.lastname?.message}
              helperText={errors?.lastname?.message ? t(errors.lastname.message) : ''}
              FormHelperTextProps={{
                color: palette.textColor.error,
              }}
              inputProps={{ ...register('lastname') }}
            />
            <Box mt="4px" position="relative">
              <TextInput
                fullWidth
                label="NIK"
                name="nik"
                error={!!errors?.nik?.message}
                helperText={errors?.nik?.message ? t(errors.nik.message) : ''}
                FormHelperTextProps={{
                  color: palette.textColor.error,
                }}
                inputProps={{ maxLength: 16, ...register('nik') }}
                maxCharacter={16}
                value={watch('nik') || ''}
              />
              {!!watch('nik') && (
                <Box
                  position="absolute"
                  right="16px"
                  top="34px"
                  height="20px"
                  sx={{ cursor: 'pointer' }}
                  onClick={handleResetNik}
                >
                  <CloseCircle size={20} />
                </Box>
              )}
            </Box>
            <Box>
              <Typography
                component="label"
                fontSize="14px"
                lineHeight="20px"
                letterSpacing="0.014px"
              >
                {t('dob')}
              </Typography>
              <Box mt="4px">
                <Controller
                  name="dob"
                  control={control}
                  render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                    <DatePicker
                      date={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" gap="8px" mt="16px">
            <Button
              fullWidth
              variant="outlined"
              color="neutral"
              onClick={() => setIsShowConfirmationAge(false)}
            >
              {tGlobal('cancel')}
            </Button>
            <Button fullWidth type="submit">
              {tGlobal('save')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column">
          <Typography fontSize="20px" lineHeight="28px" align="center" mb="6px">
            {tBottomSheet('title')}
          </Typography>
          <Typography
            fontSize="14px"
            align="center"
            fontWeight="400"
            color="textColor.secondaryDark"
            mb="40px"
          >
            {tBottomSheet('description')}
          </Typography>
          <Button onClick={handleToDetail}>{tBottomSheet('btn')}</Button>
        </Box>
      )}
    </BottomSheet>
  )
}

export default BottomSheetConfirmationAge
